import { Component } from '@angular/core';

@Component({
  selector: 'app-chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['./chat-widget.component.css']
})
export class ChatWidgetComponent {
  isChatVisible = false;
  userMessage = '';
  messages = [
    { sender: 'Bot', text: 'Hola, ¿en qué puedo ayudarte?' }
  ];

  toggleChat() {
    this.isChatVisible = !this.isChatVisible;
  }

  sendMessage() {
    if (this.userMessage.trim()) {
      // Agregar mensaje del usuario
      this.messages.push({ sender: 'Tú', text: this.userMessage });

      // Limpiar el campo de entrada
      this.userMessage = '';

      // Simular respuesta del bot
      setTimeout(() => {
        this.messages.push({ sender: 'Bot', text: 'Gracias por tu mensaje.' });
      }, 1000);
    }
  }
}
