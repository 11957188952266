<div id="chat-widget" (click)="toggleChat()">
  <span>&#128172;</span>
</div>

<div id="chat-container" *ngIf="isChatVisible">
  <div id="chat-header">Chat en Vivo</div>
  <div id="chat-messages">
    <div *ngFor="let message of messages">
      <strong>{{ message.sender }}:</strong> {{ message.text }}
    </div>
  </div>
  <div id="chat-input">
    <input [(ngModel)]="userMessage" placeholder="Escribe un mensaje..." />
    <button (click)="sendMessage()">Enviar</button>
  </div>
</div>
