import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {enableProdMode} from "@angular/core";

enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule, { ngZone: 'zone.js', ngZoneEventCoalescing: true, ngZoneRunCoalescing: true })
  .catch(err => console.error(err)).then(()=>{
    console.log('Widget Loaded')
});
